import Client from "../client/Client"
import version from "../version"
const resource = version + "/tenant/official-schools"

function gets(official_school_id, params) {
  return Client.get(`${resource}/${official_school_id}/event-schools/`, { params })
}
function get(official_school_id, id) {
  return Client.get(`${resource}/${official_school_id}/event-schools/${id}/`)
}
function create(official_school_id, data) {
  return Client.post(`${resource}/${official_school_id}/event-schools/`, data)
}
function update(official_school_id, id, data) {
  return Client.put(`${resource}/${official_school_id}/event-schools/${id}/`, data)
}
function remove(official_school_id, id) {
  return Client.delete(`${resource}/${official_school_id}/event-schools/${id}/`)
}
function importList(data) {
  return Client.post(`${version}/tenant/event-schools/import/`, data)
}

const api = {
  gets,
  get,
  create,
  update,
  remove,
  importList
}
export default api
