import Client from "../client/Client"
import version from "../version"
const resource = version + "/client/organizations"

function gets(params) {
  return Client.get(`${resource}/`, { params })
}
function get(id, params) {
  return Client.get(`${resource}/${id}/`, { params })
}
function exportList(params) {
  return Client.post(`${resource}/export-csv/`, { params })
}
function create(data) {
  return Client.post(`${resource}/`, data)
}
function update(id, data) {
  return Client.put(`${resource}/${id}/`, data)
}
function exportClients(params) {
  return Client.get(`${resource}/export-csv/`, { params })
}
function getClientDetail() {
  return Client.get(`${version}/client/me/`)
}
function updateStatus(id, data) {
  return Client.put(`${resource}/${id}/change-status/`, data)
}
function resetPassword(id, data) {
  return Client.put(`${resource}/${id}/reset-password/`, data)
}
function getBrandSetting(brand_id, params) {
  return Client.get(`${resource}/${brand_id}/config/theme-setting/`, { params })
}

function updateBrandSetting(brand_id, data) {
  return Client.post(`${resource}/${brand_id}/config/theme-setting/upsert/`, data)
}

const api = {
  gets,
  get,
  exportList,
  create,
  update,
  exportClients,
  getClientDetail,
  updateStatus,
  resetPassword,
  getBrandSetting,
  updateBrandSetting
}

export default api
