import Client from "../client/Client"
import version from "../version"
const resource = version + "/tenant/student/billing"
const resourceDetail = version + "/tenant/student/billing-detail"

function gets(params) {
  return Client.get(`${resource}/`, { params })
}
function getBillingsUnconfirm(params) {
  return Client.get(`${version}/tenant/student/billing-selected/`, { params })
}
function get(id, params) {
  return Client.get(`${resource}/${id}/`, { params })
}
function create(data) {
  return Client.post(`${resource}/`, data)
}
function update(id, data) {
  return Client.put(`${resource}/${id}/`, data)
}
function remove(id, data) {
  return Client.delete(`${resource}/${id}/`, data)
}

// detail
function getDetailBilList(params) {
  return Client.get(`${resourceDetail}/`, { params })
}
function getDetailBilListRecap(params) {
  return Client.get(`${version}/tenant/student/billing-recap/`, { params })
}
function getDetailBilDetail(id, params) {
  return Client.get(`${resourceDetail}/${id}/`, { params })
}
function calculateAmount(data) {
  return Client.post(`${resourceDetail}/calculate-addition-fee/`, data)
}
function calculateTextFeeAmount(data) {
  return Client.post(`${resourceDetail}/calculate-text-fee/`, data)
}
function calculateDiscountRate(data) {
  return Client.post(`${resourceDetail}/calculate-discount-rate/`, data)
}
function createDetailBil(data) {
  return Client.post(`${resourceDetail}/`, data)
}
function updateDetailBil(id, data) {
  return Client.put(`${resourceDetail}/${id}/`, data)
}
function removeDetailBil(id) {
  return Client.delete(`${resourceDetail}/${id}/`)
}

const api = {
  gets,
  getDetailBilListRecap,
  getBillingsUnconfirm,
  get,
  create,
  update,
  remove,
  getDetailBilList,
  getDetailBilDetail,
  calculateAmount,
  calculateTextFeeAmount,
  calculateDiscountRate,
  createDetailBil,
  updateDetailBil,
  removeDetailBil
}
export default api
