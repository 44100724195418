import Client from "../client/ClientControlPlane"
import version from "../version"
const resource = version + "/client/approvals"

// base crud
function gets(params) {
  return Client().get(`${resource}/`, {
    params
  })
}
function get(id) {
  return Client().get(`${resource}/${id}/`)
}
function getProcesses(id) {
  return Client().get(`${resource}/${id}/processes/`)
}
function createRequestApproval(data) {
  return Client().post(`${resource}/`, data)
}
function update(id, data) {
  return Client().put(`${resource}/${id}/`, data)
}
function updateStatus(id, data) {
  return Client().put(`${resource}/${id}/change-status/`, data)
}

const api = {
  gets,
  get,
  getProcesses,
  createRequestApproval,
  update,
  updateStatus
}
export default api
