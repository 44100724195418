import { Spin } from "antd"
import { useEffect, useState } from "react"
import axios from "axios"
import { getBaseDataLocal } from "lib/localstorage"
import { useNavigate } from "react-router-dom"
import systemMaintanance from "assets/images/systemMaintanance.png"
import "./index.scss"
import { apiSetting } from "api"
import { getDateSetting } from "lib/function"
import moment from "moment-timezone"

const baseDomain = process.env.REACT_APP_ID_URL

export default function MaintenancePage() {
  const navigate = useNavigate()
  const { access_token } = getBaseDataLocal()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})

  function getTimezone() {
    const timezone = moment.tz.guess()
    const offset = moment.tz(timezone).format("Z")
    const formattedOffset = `GMT${offset}`

    return `${formattedOffset} ${timezone}`
  }

  function fetchProfile() {
    axios({
      url: `${baseDomain}/api/v1/account/view/profile/`,
      method: "get",
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json"
      }
    })
      .then((response) => {
        console.log(response.data)
        navigate("/dashboard")
      })
      .catch((err) => {
        console.log(err)
        fecthSetting()
      })
  }

  const fecthSetting = async () => {
    setLoading(true)
    try {
      const { data } = await apiSetting.getSystemMaintenanceInfo()
      setData(data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="__content">
      <Spin spinning={loading}>
        <div style={{ height: "calc(100vh - 130px)" }}>
          <div className="h-full flex flex-col justify-center items-center">
            <div>
              <img
                className="__image_maintanance"
                src={systemMaintanance}
                alt="systemMaintanance"
              />
            </div>
            <div className="text-[24px] font-[700] text-[#326E86] text-center">
              System Maintenance
            </div>
            <div className="w-[600px] mt-4 text-[16px] text-center text-[#646970]">
              [System Maintenance Scheduled] We'll be performing system maintenance from{" "}
              {data?.value?.from && getDateSetting(data?.value?.from)} to{" "}
              {data?.value?.to && getDateSetting(data?.value?.to)} ({getTimezone()} Time) to improve
              system performance and reliability. During this time, you may experience temporary
              disruptions to the service. We apologize for any inconvenience this may cause.
            </div>
          </div>
        </div>
      </Spin>
    </div>
  )
}
