import React, { useState } from "react"
import Icon from "@ant-design/icons"
import MyHeader from "./Header/Header"
import { useStore } from "components/ui"
import Navbar from "./Navbar"
import menus from "pages/Authen/menus"
import "./index.css"

function convertMenus(menus, initPermission) {
  // let permission = initPermission.filter((i) => i.includes("view"))
  // function checkPermission(code) {
  //   return !!permission.find((i) => i === code)
  // }
  function loop(lists, urlParent) {
    return lists.map((item) => {
      const IconX = () => <span dangerouslySetInnerHTML={{ __html: item.icon }} />

      const url = urlParent + item.url
      return {
        ...item,
        url: url,
        icon: !item.type ? <Icon component={IconX} /> : <img src={item.icon} alt="icon" />,
        children: item.children?.length > 0 ? loop(item.children, url) : []
      }
    })
  }
  return loop(menus, "")
}

function MyLayout({ children }) {
  const isShow = localStorage.getItem("isShow")
  const { user, permission, settingTheme } = useStore()
  const [isNav, setIsNav] = useState(isShow === "false" ? false : true)

  function togleSidebar(e) {
    localStorage.setItem("isShow", !isNav)
    setIsNav((c) => !c)
  }
  return (
    <div className="flex min-h-screen">
      <Navbar
        settingTheme={settingTheme}
        items={convertMenus(menus, permission)}
        togleSidebar={togleSidebar}
        isNav={isNav}
      />
      <section className="flex-grow w-0 bg_layout_content">
        <header className="flex justify-end bg-white border-b py-2 pr-6 pl-4 w-full sticky top-0 z-20">
          <MyHeader user={user} />
        </header>
        <div className="px-6 py-4">
          {/* {useLocation()?.pathname !== "/projects" && (
            <div className="mb-2 wapper_small">
              <DropdownProjects />
            </div>
          )} */}

          {children}
        </div>
      </section>
    </div>
  )
}
export default MyLayout
