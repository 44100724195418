import Client from "../client/Client"
import version from "../version"
const resource = version + "/tenant/csv-import/history"

function gets(params) {
  return Client.get(`${resource}/`, { params })
}
function get(id) {
  return Client.get(`${resource}/${id}/`)
}

const api = {
  gets,
  get
}
export default api
