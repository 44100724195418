import Client from "../client/Client"
import version from "../version"
const resource = version + "/tenant/lessons/all"

function gets(params) {
  return Client.get(`${resource}/`, { params })
}

const api = {
  gets
}
export default api
