// import { useEffect, useRef, useState } from "react"
import "./index.scss"
import { Alert } from "antd"

export default function AlertError({ error }) {
  return (
    <div className="__login mb-5">
      <Alert
        message={null}
        description={error}
        icon={
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 1.25C5.16797 1.25 1.25 5.16797 1.25 10C1.25 14.832 5.16797 18.75 10 18.75C14.832 18.75 18.75 14.832 18.75 10C18.75 5.16797 14.832 1.25 10 1.25ZM13.2305 13.3242L11.9414 13.3184L10 11.0039L8.06055 13.3164L6.76953 13.3223C6.68359 13.3223 6.61328 13.2539 6.61328 13.166C6.61328 13.1289 6.62695 13.0938 6.65039 13.0645L9.19141 10.0371L6.65039 7.01172C6.62679 6.98309 6.61369 6.94725 6.61328 6.91016C6.61328 6.82422 6.68359 6.75391 6.76953 6.75391L8.06055 6.75977L10 9.07422L11.9395 6.76172L13.2285 6.75586C13.3145 6.75586 13.3848 6.82422 13.3848 6.91211C13.3848 6.94922 13.3711 6.98438 13.3477 7.01367L10.8105 10.0391L13.3496 13.0664C13.373 13.0957 13.3867 13.1309 13.3867 13.168C13.3867 13.2539 13.3164 13.3242 13.2305 13.3242Z"
              fill="#F87171"
            />
          </svg>
        }
        type="error"
        showIcon
      />
    </div>
  )
}
