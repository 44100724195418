import { CaretDownOutlined } from "@ant-design/icons"
import { Select } from "antd"
import { useStore } from "components/ui"
import dayjs from "dayjs"
import { getLocale } from "lib/function"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import("dayjs/locale/en")

export default function ButtonI18n() {
  const { setLocale } = useStore()
  const { t, i18n } = useTranslation()

  function onChangeLanguage(v) {
    i18n.changeLanguage(v)
    setLocale(v)
    dayjs.locale(v === "jp" ? "ja" : "en")
  }

  useEffect(() => {
    let initLocale = getLocale(t)
    const list = getOptions(t)
    if (list.find((i) => i.value === i18n.language)) {
      setLocale(i18n.language)
    } else {
      setLocale(initLocale)
      i18n.changeLanguage(initLocale)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Select
      suffixIcon={<CaretDownOutlined style={{ color: "#646970" }} />}
      onChange={onChangeLanguage}
      className="min-w-[140px]"
      value={i18n.language}
      defaultValue={i18n.language}
      options={getOptions(t)}
    />
  )
}

const icJP = (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 0 800 800"
    enableBackground="new 0 0 800 800"
    fill="#000000"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <g>
        {" "}
        <path
          fill="#FFFFFF"
          d="M116.129,154.839H25.807v490.322h748.387V154.839H167.742c-7.126,0-12.903-5.775-12.903-12.903h-25.807 C129.032,149.063,123.255,154.839,116.129,154.839z M400,258.064c78.264,0,141.936,63.673,141.936,141.936 c0,78.263-63.672,141.936-141.936,141.936S258.064,478.263,258.064,400C258.064,321.738,321.736,258.064,400,258.064z"
        ></path>{" "}
        <path d="M787.097,129.032H167.742c-7.126,0-12.903,5.775-12.903,12.903s5.777,12.903,12.903,12.903h606.451v490.322H25.807V154.839 h90.322c7.126,0,12.903-5.775,12.903-12.903s-5.777-12.903-12.903-12.903H12.903C5.777,129.032,0,134.808,0,141.936v516.129 c0,7.128,5.777,12.903,12.903,12.903h774.193c7.126,0,12.903-5.775,12.903-12.903V141.936 C800,134.808,794.223,129.032,787.097,129.032z"></path>{" "}
        <path
          fill="#ED1F34"
          d="M400,517.129c-64.585,0-117.129-52.544-117.129-117.129S335.415,282.871,400,282.871 S517.129,335.415,517.129,400S464.585,517.129,400,517.129z"
        ></path>{" "}
        <path
          fill="#ED1F34"
          d="M400,516.129c64.034,0,116.129-52.095,116.129-116.129S464.034,283.871,400,283.871 S283.871,335.966,283.871,400S335.966,516.129,400,516.129z"
        ></path>{" "}
        <path d="M400,541.936c78.264,0,141.936-63.673,141.936-141.936c0-78.262-63.672-141.936-141.936-141.936 S258.064,321.738,258.064,400C258.064,478.263,321.736,541.936,400,541.936z M400,283.871c64.034,0,116.129,52.095,116.129,116.129 S464.034,516.129,400,516.129S283.871,464.034,283.871,400S335.966,283.871,400,283.871z"></path>{" "}
      </g>{" "}
    </g>
  </svg>
)

const icEn = (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 36 36"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    role="img"
    className="iconify iconify--twemoji"
    preserveAspectRatio="xMidYMid meet"
    fill="#000000"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      <path
        fill="#00247D"
        d="M0 9.059V13h5.628zM4.664 31H13v-5.837zM23 25.164V31h8.335zM0 23v3.941L5.63 23zM31.337 5H23v5.837zM36 26.942V23h-5.631zM36 13V9.059L30.371 13zM13 5H4.664L13 10.837z"
      ></path>
      <path
        fill="#CF1B2B"
        d="M25.14 23l9.712 6.801a3.977 3.977 0 0 0 .99-1.749L28.627 23H25.14zM13 23h-2.141l-9.711 6.8c.521.53 1.189.909 1.938 1.085L13 23.943V23zm10-10h2.141l9.711-6.8a3.988 3.988 0 0 0-1.937-1.085L23 12.057V13zm-12.141 0L1.148 6.2a3.994 3.994 0 0 0-.991 1.749L7.372 13h3.487z"
      ></path>
      <path
        fill="#EEE"
        d="M36 21H21v10h2v-5.836L31.335 31H32a3.99 3.99 0 0 0 2.852-1.199L25.14 23h3.487l7.215 5.052c.093-.337.158-.686.158-1.052v-.058L30.369 23H36v-2zM0 21v2h5.63L0 26.941V27c0 1.091.439 2.078 1.148 2.8l9.711-6.8H13v.943l-9.914 6.941c.294.07.598.116.914.116h.664L13 25.163V31h2V21H0zM36 9a3.983 3.983 0 0 0-1.148-2.8L25.141 13H23v-.943l9.915-6.942A4.001 4.001 0 0 0 32 5h-.663L23 10.837V5h-2v10h15v-2h-5.629L36 9.059V9zM13 5v5.837L4.664 5H4a3.985 3.985 0 0 0-2.852 1.2l9.711 6.8H7.372L.157 7.949A3.968 3.968 0 0 0 0 9v.059L5.628 13H0v2h15V5h-2z"
      ></path>
      <path fill="#CF1B2B" d="M21 15V5h-6v10H0v6h15v10h6V21h15v-6z"></path>
    </g>
  </svg>
)
//                {t("Description")}
function getOptions(t) {
  return [
    {
      label: (
        <div className="flex gap-2 items-center">
          {icEn} {t("English")}
        </div>
      ),
      value: "en"
    },
    {
      label: (
        <div className="flex gap-2 items-center">
          {icJP} {t("Japanese")}
        </div>
      ),
      value: "jp"
    }
  ]
}
