import Client from "../client/Client"
import version from "../version"
const resource = version + "/tenant/courses"
const resourceDiscount = version + "/tenant/discount-courses"

function gets(params) {
  return Client.get(`${resource}/`, { params })
}
function get(id) {
  return Client.get(`${resource}/${id}/`)
}
function create(data) {
  return Client.post(`${resource}/`, data)
}
function update(id, data) {
  return Client.put(`${resource}/${id}/`, data)
}
function remove(id) {
  return Client.delete(`${resource}/${id}/`)
}

// discount setting
function getsDiscountSetting(params) {
  return Client.get(`${resourceDiscount}/`, { params })
}
function getDiscountSetting(id) {
  return Client.get(`${resourceDiscount}/${id}/`)
}
function createDiscountSetting(data) {
  return Client.post(`${resourceDiscount}/`, data)
}
function updateDiscountSetting(id, data) {
  return Client.put(`${resourceDiscount}/${id}/`, data)
}
function removeDiscountSetting(id) {
  return Client.delete(`${resourceDiscount}/${id}/`)
}

const api = {
  gets,
  get,
  create,
  update,
  remove,
  // discount setting
  getsDiscountSetting,
  getDiscountSetting,
  createDiscountSetting,
  updateDiscountSetting,
  removeDiscountSetting
}
export default api
