import { Form, Input, Button } from "antd"
import { apiUser } from "api"
import { useState } from "react"
import LabelInput from "components/ui/LabelInput"
import { setCookieAuth } from "lib/cookie"
import { useStore } from "components/ui"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import "./Login.scss"
import AlertError from "components/AlertError"

const baseDomain = process.env.REACT_APP_ID_URL
const baseDomainCore = process.env.REACT_APP_CORE_URL

const OTPRecoveryCode = ({ email, onDirrectToAuthenCode, onBack }) => {
  const navigate = useNavigate()
  const { setAuthStore } = useStore()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [isSubmited, setIsSubmited] = useState(false)

  const onFinish = async (values) => {
    setError("")
    setLoading(true)
    try {
      const { data } = await apiUser.verifyRecoveryCode({ email: email, code: values.code })
      const { access_token, refresh_token } = data
      fetchBaseApi({ access_token, refresh_token })
    } catch (error) {
      setLoading(false)
      const { data } = error.response
      setError(data?.error_message[0])
    } finally {
    }
  }

  async function fetchBaseApi(token) {
    const { access_token, refresh_token } = token
    let userInfo = {}
    setLoading(true)
    axios({
      url: `${baseDomain}/api/v1/account/view/profile/`,
      method: "get",
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json"
      }
    })
      .then((response) => {
        const { data } = response.data
        userInfo = data
        return axios({
          url: `${baseDomainCore}/api/v1/staff/configs/?page_num=1&page_size=1000`,
          method: "get",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json"
          }
        })
      })
      .then((secondResponse) => {
        const { data } = secondResponse
        setLoading(false)

        let baseData = {
          user: userInfo,
          settings: data?.data,
          permission: [],
          access_token: access_token
        }
        setCookieAuth({ access_token, refresh_token })
        setAuthStore(baseData)
        navigate("/dashboard")
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
  }

  const onChange = () => {
    setIsSubmited(true)
    setError("")
  }

  return (
    <div className="">
      <h3 className="text-[24px] text-blue-dark font-bold text-center mt-8 mb-6">
        Two-Factor Recovery Code
      </h3>

      {error !== "" && <AlertError error={error} />}

      <div className="text-center text-[#646970]">
        Enter one of the recovery codes that you had received when you first set up two-factor
        authentication
      </div>

      <div className="mt-[32px]">
        <Form
          name="normal_login"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            label={<LabelInput label="Recovery code" required={true} />}
            required={false}
            name="code"
            rules={[{ required: true, message: "Recovery code is required" }]}
          >
            <Input size="large" placeholder="Enter recovery code" onChange={onChange} />
          </Form.Item>

          <div>
            <span
              className="cursor-pointer text-[#326E86] font-[700]"
              onClick={onDirrectToAuthenCode}
            >
              Enter verification code instead
            </span>
          </div>

          {/* Submit button */}
          <Form.Item>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              className="w-full mt-5 !rounded-lg"
              loading={loading}
              disabled={!isSubmited}
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className="text-center mt-[16px]">
        <span>
          Back to {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className="cursor-pointer text-[#326E86]" onClick={onBack}>
            Sign in
          </a>
        </span>
      </div>
    </div>
  )
}

export default OTPRecoveryCode
