import { useRef } from "react"
import "./index.css"

export default function Collapae({ isOpen, children, isShow, count }) {
  const content = useRef()
  const className = isShow ? "px-4" : "absolute left-full top-0 bg-slate-900 shadow hidden rounded"

  let duration = count / 20
  return (
    <div
      className={`__collap1se ${className}`}
      style={{
        maxHeight: isOpen || !isShow ? 40 * count : 0,
        overflow: "hidden",
        transition: `${duration}s max-height`
      }}
    >
      <div ref={content}>{children}</div>
    </div>
  )
}
