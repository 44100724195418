import Client from "../client/ClientCommon"
import version from "../version"

function gets(params) {
  return Client.get(`${version}/activity/logs/`, { params })
}
function getListObjects(params) {
  return Client.get(`${version}/module/all/`, { params })
}
function exportList(data) {
  return Client.post(`${version}/activity/export/`, data)
}

const api = {
  gets,
  getListObjects,
  exportList
}
export default api
