import Client from "../client/Client"
import version from "../version"
const resource = version + "/tenant"

function checkExistedDomain(params) {
  return Client.get(`${version}/public/domains/sub-domain-existed/`, { params })
}

function getSchools(params) {
  return Client.get(`${resource}/official-schools/all/`, { params })
}

function getSchoolGrades(params) {
  return Client.get(`${resource}/school-grades/all/`, { params })
}

function getSchoolClasses(params) {
  return Client.get(`${resource}/school-classes/all/`, { params })
}

function getRegions(params) {
  return Client.get(`${resource}/regions/all/`, { params })
}

function getAreas(params) {
  return Client.get(`${resource}/areas/all/`, { params })
}
function getAllSubjects(params) {
  return Client.get(`${resource}/subjects/all/`, { params })
}
function getTimeTable(school_id) {
  return Client.get(`${resource}/schools/${school_id}/time-tables/latest/`)
}
function getAllRooms(school_id) {
  return Client.get(`${resource}/schools/${school_id}/rooms/all/`)
}
function getContractCourses(params) {
  return Client.get(`${resource}/contract-courses/selection/`, { params })
}
function getSubjects(params) {
  return Client.get(`${resource}/subjects/selection/`, { params })
}

const api = {
  checkExistedDomain,
  getSchools,
  getSchoolGrades,
  getSchoolClasses,
  getRegions,
  getAreas,
  getAllSubjects,
  getTimeTable,
  getAllRooms,
  getContractCourses,
  getSubjects
}
export default api
