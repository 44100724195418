import Client from "../client/Client"
import version from "../version"
const resource = version + "/tenant/report"

// Month target
function getsMonthTarget(params) {
  return Client.get(`${resource}/month-targets/`, { params })
}
function updateMonthTarget(data) {
  return Client.post(`${resource}/month-targets/upsert/`, data)
}

// Course target
function getsCourseTarget(params) {
  return Client.get(`${resource}/course-targets/`, { params })
}
function updateCourseTarget(data) {
  return Client.post(`${resource}/course-targets/upsert/`, data)
}

// Day results
function getsDays(params) {
  return Client.get(`${resource}/days/`, { params })
}
function getsDayDetailBySchool(day_report_school_id, params) {
  return Client.get(`${resource}/days/schools/${day_report_school_id}/`, { params })
}

// Month results
function getsMonths(params) {
  return Client.get(`${resource}/months/`, { params })
}
function updateAjustment(id, data) {
  return Client.put(`${resource}/months/${id}/`, data)
}
function getsAdMedias(params) {
  return Client.get(`${resource}/ad-medias/`, { params })
}

// School Status
function getsSchoolsStatus(params) {
  return Client.get(`${resource}/schools/`, { params })
}

// Haft Year + Year
function getsHaftYear(params) {
  return Client.get(`${resource}/haft-terms/`, { params })
}
function getsYear(params) {
  return Client.get(`${resource}/years/`, { params })
}

// Haft Comparison Year + Year
function getsHaftComparison(params) {
  return Client.get(`${resource}/present-month-sale/`, { params })
}
function getsComparison(params) {
  return Client.get(`${resource}/present-half-sale/`, { params })
}

// Student Sales Status Report
function getStudentSalesStatusReport(params) {
  return Client.get(`${resource}/student-sale/`, { params })
}

// Contract Unit Price Report
function getContractUnitPrice(params) {
  return Client.get(`${resource}/month-graderank-contract/`, { params })
}

// Seasonal Training Report
function getSeasonalTrainingReport(params) {
  return Client.get(`${resource}/graderank-season/`, { params })
}

// Timed Course Report
function getTimedCourseReport(params) {
  return Client.get(`${resource}/schedule-course/`, { params })
}
function getDetailTimedCourseReport(params) {
  return Client.get(`${resource}/schedule-course-detail/`, { params })
}

const api = {
  getsMonthTarget,
  updateMonthTarget,
  getsCourseTarget,
  updateCourseTarget,
  getsDays,
  getsDayDetailBySchool,
  getsMonths,
  updateAjustment,
  getsAdMedias,
  getsSchoolsStatus,
  getsHaftYear,
  getsYear,
  getsHaftComparison,
  getsComparison,
  getStudentSalesStatusReport,
  getContractUnitPrice,
  getSeasonalTrainingReport,
  getTimedCourseReport,
  getDetailTimedCourseReport
}
export default api
