import { Setting_Password_Policy } from "lib/Const"

const list = [
  {
    key: Setting_Password_Policy.password_min_length,
    label: "Minimum Password Length (Characters)"
  },
  {
    key: Setting_Password_Policy.password_max_length,
    label: "Maximum Password Length (Characters)"
  },
  { key: Setting_Password_Policy.allow_special_character, label: "Allow Special Symbol" },
  { key: Setting_Password_Policy.numbers_required, label: "Require numbers" },
  { key: Setting_Password_Policy.alphabetic_letter_required, label: "Require alphabetic letter" },
  { key: Setting_Password_Policy.lowercase_letter_required, label: "Require lowercase letter" }
]

function getLabel(key, data) {
  const find = list.find((v) => v.key === key)
  if (find) {
    if (
      find.key === Setting_Password_Policy.password_min_length ||
      find.key === Setting_Password_Policy.password_max_length
    ) {
      return `${find.label}: ${data?.value} characters`
    } else if (find.key === Setting_Password_Policy.allow_special_character) {
      return `${find.label}: ${data?.value}`
    } else {
      return find.label
    }
  }
  return ""
}

export function getListSetting(keys, values) {
  var _new = values
    .map((item, index) => {
      return {
        ...item,
        key: keys[index],
        label: getLabel(keys[index], item),
        is_active: false
      }
    })
    .filter((v) => v.key !== Setting_Password_Policy.password_enforce_history)
  _new.forEach((item, index) => {
    if (typeof item.value == "boolean" && !item.value) {
      _new = _new.filter((v) => v.key !== item.key)
    }
  })
  return _new
}
