import Client from "../client/ClientNotification"
import version from "../version"
const resource = version + "/send_notification"
const resourceInApp = version + "/notification"

function gets(params) {
  return Client.get(`${resource}/view/`, { params })
}
function get(id) {
  return Client.get(`${resource}/detail/${id}/`)
}
function create(data) {
  return Client.post(`${resource}/create/`, data)
}
function update(id, data) {
  return Client.put(`${resource}/update/${id}/`, data)
}
function remove(id) {
  return Client.delete(`${resource}/delete/${id}/`)
}

// in-app
function getCategories(params) {
  return Client.get(`${resourceInApp}/categories/`, { params })
}
function getNotificationByCategory(params) {
  return Client.get(`${resourceInApp}/alls/`, { params })
}
function getCountNoti(params) {
  return Client.get(`${resourceInApp}/count/`, { params })
}
function markReadByNoti(id, is_read) {
  return Client.put(`${resourceInApp}/markread?noti_id=${id}&is_read=${is_read}`)
}
function markReadAllNoti(data) {
  return Client.put(`${resourceInApp}/markread/alls/`, data)
}
function removeNotiInapp(id) {
  return Client.delete(`${resourceInApp}/delete/${id}/`)
}

const api = {
  gets,
  get,
  create,
  update,
  remove,
  // in app
  getCategories,
  getNotificationByCategory,
  getCountNoti,
  markReadByNoti,
  markReadAllNoti,
  removeNotiInapp
}
export default api
