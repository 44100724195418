import Client from "../client/ClientControlPlane"
import version from "../version"
const resourceBase = version + "/client"
const resourceApproval = version + "/client/approval-settings"

// approval setting
function getListApprovalSetting(params) {
  return Client().get(`${resourceBase}/approval-setting-assigns/`, { params })
}
function getDetailApprovalSetting(id) {
  return Client().get(`${resourceBase}/approval-setting-assigns/${id}/`)
}
function getListFullApprovalModule(params) {
  return Client().get(`${resourceBase}/approval-modules/full/`, { params })
}
function getListApprovalModule(params) {
  return Client().get(`${resourceBase}/approval-modules/all/`, { params })
}
function getListApprovalObject(params) {
  return Client().get(`${resourceBase}/approval-objects/all/`, { params })
}
function getListApprovalMethod(params) {
  return Client().get(`${resourceBase}/approval-methods/all/`, { params })
}
function checkExistApprovalSetting(params) {
  return Client().get(`${resourceApproval}/exsited/`, { params })
}
function createApprovalSetting(data) {
  return Client().post(`${resourceApproval}/`, data)
}
function updateApprovalSetting(data) {
  return Client().post(`${resourceBase}/approval-setting-assigns/upsert/`, data)
}
function updateApprovalSettingStatus(id, data) {
  return Client().put(`${resourceBase}/approval-setting-assigns/${id}/change-status/`, data)
}
function removeApprovalSetting(id) {
  return Client().delete(`${resourceApproval}/${id}/`)
}

const api = {
  getListApprovalSetting,
  getListFullApprovalModule,
  getListApprovalModule,
  getListApprovalObject,
  getListApprovalMethod,
  getDetailApprovalSetting,
  checkExistApprovalSetting,
  createApprovalSetting,
  updateApprovalSetting,
  updateApprovalSettingStatus,
  removeApprovalSetting
}
export default api
