import Client from "../client/Client"
import version from "../version"
const resource = version + "/client/config"
const resourceEmail = version + "/client/setting/email-setting"
const resourceHistory = version + "/client/setting/system-maintenance"

// Overview
function get(key) {
  return Client.get(`${resource}/${key}/`)
}
function getAllSetting(params) {
  return Client.get(`${version}/client/configs/`, { params })
}
function getBrandMaintenances() {
  return Client.get(`${version}/client/configs/?search=sm-setting`)
}
function getSystemMaintenanceInfo() {
  return Client.get(`${version}/public/config/sm-setting/`)
}
function update(key, data) {
  return Client.post(`${resource}/${key}/upsert/`, data)
}

//Email Setting
function getListEmails(params) {
  return Client.get(`${resourceEmail}/`, { params })
}
function getDetailEmail(id) {
  return Client.get(`${resourceEmail}/${id}/`)
}
function createEmail(data) {
  return Client.post(`${resourceEmail}/`, data)
}
function updateEmail(id, data) {
  return Client.put(`${resourceEmail}/${id}/`, data)
}

// system maintenance
function getListHistory(params) {
  return Client.get(`${resourceHistory}/history/`, { params })
}

const api = {
  get,
  getAllSetting,
  getBrandMaintenances,
  getSystemMaintenanceInfo,
  update,
  getListEmails,
  getDetailEmail,
  createEmail,
  updateEmail,
  getListHistory
}
export default api
