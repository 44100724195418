import { Suspense } from "react"
import { Outlet, BrowserRouter as Router, Routes, Route, Link } from "react-router-dom"
import Layout from "components/common/Layout"
import { Result } from "antd"
import SuspenseComponent from "./SuspenseComponent"
import pageList from "./PageList"
import Login from "pages/Authen/Main"
import ForgotPassword from "pages/Authen/ForgotPassword"
import MaintenancePage from "pages/MaintenancePage"
import { useStore } from "components/ui"

function ProtectedRoute() {
  return (
    <Layout>
      <Suspense fallback={<SuspenseComponent />}>
        <Outlet />
      </Suspense>
    </Layout>
  )
}

export default function Routers() {
  const { access_token, permission } = useStore()
  return (
    <Router>
      <Routes>
        {access_token && permission ? (
          <>
            <Route element={<ProtectedRoute />}>
              {pageList().map(({ Element, path, resource, pageName }, key) => {
                const actionPermission = permission[resource] || {}
                return (
                  <Route
                    path={path}
                    key={key}
                    element={<Element actionPermission={actionPermission} />}
                  />
                )
              })}
              <Route path="/maintenance" element={<MaintenancePage />} />
              <Route path="*" element={<NotLoadAuthorization />} />
            </Route>
          </>
        ) : (
          <Route element={<Outlet />}>
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="*" element={<Login />} />
          </Route>
        )}
      </Routes>
    </Router>
  )
}

const NotLoadAuthorization = () => {
  return (
    <div className="__content">
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={<Link to="/">Back Home</Link>}
      />
    </div>
  )
}
