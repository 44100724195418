import { Form, Input, Button, Checkbox } from "antd"
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons"
import { apiCore, apiUser } from "api"
import { useStore } from "components/ui"
import { useState, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { setCookieAuth } from "lib/cookie"
import LabelInput from "components/ui/LabelInput"
import ModalVerifyCode from "components/ModalVerifyCode"
import ModalPopupConfirm from "components/ModalPopupConfirm"
import { isEmailValid, hasWhiteSpace } from "lib/validate"
import AlertError from "components/AlertError"
import "./Login.scss"
import { getThemeConfigs } from "lib/function"

const LoginForm = ({ user, onNext, onNextFirstLogin, onNextToSpecific2Fa, onForgot }) => {
  const navigate = useNavigate()
  const { setAuthStore } = useStore()
  // const access_token = getCookieAuth()?.access_token
  const rememberUsername = localStorage.getItem("remember_username")
  const rememberPassword = localStorage.getItem("remember_password")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalConfirm, setIsModalConfirm] = useState(false)
  const [userData, setUserData] = useState({})
  const [isSubmited, setIsSubmited] = useState(false)
  const newAuth = useRef()

  const [form] = Form.useForm()
  const formRef = useRef(null)

  // Callback function when the form is submitted
  const onFinish = async (values) => {
    setError("")
    setLoading(true)
    try {
      // Make a login API call using the provided credentials
      const { data } = await apiUser.login(values)

      if (values?.remember) {
        localStorage.setItem("remember_username", values?.username)
        localStorage.setItem("remember_password", values?.password)
      } else {
        localStorage.removeItem("remember_username")
        localStorage.removeItem("remember_password")
      }
      setUserData(data?.user)
      fetchBaseApi(data?.token)
      // if (!data?.user?.last_login) {
      //   await apiUser.sendEmail({ email: values.username })
      //   onNextFirstLogin(values.username)
      // } else {
      //   if (data?.token) {
      //     fetchBaseApi(data?.token)
      //   } else {
      //     if (data?.systerm_2fa) {
      //       // default two_fa = 4 when systerm_2fa=true
      //       if (data?.user?.two_fa === 4) {
      //         onNext(values.username, data?.user)
      //       } else {
      //         if (data?.user?.two_fa === 3) {
      //           onNext(values.username, data?.user)
      //         } else {
      //           if (data?.user?.two_fa === 1) {
      //             await apiUser.sendEmail({ email: values.username })
      //             onNextToSpecific2Fa(values.username, data?.user, data?.user?.two_fa)
      //           } else {
      //             if (!data?.user?.is_scan_qrcode) {
      //               setIsModalOpen(true)
      //             } else {
      //               onNextToSpecific2Fa(values.username, data?.user, data?.user?.two_fa)
      //             }
      //           }
      //         }
      //       }
      //     } else {
      //       if (data?.user?.two_fa === 3) {
      //         onNext(values.username, data?.user)
      //       } else {
      //         if (data?.user?.two_fa === 1) {
      //           await apiUser.sendEmail({ email: values.username })
      //           onNextToSpecific2Fa(values.username, data?.user, data?.user?.two_fa)
      //         } else {
      //           if (!data?.user?.is_scan_qrcode) {
      //             setIsModalOpen(true)
      //           } else {
      //             onNextToSpecific2Fa(values.username, data?.user, data?.user?.two_fa)
      //           }
      //         }
      //       }
      //     }
      //   }
      // }
    } catch (error) {
      const { data } = error.response
      setError(data?.error_message[0])
    } finally {
      setLoading(false)
    }
  }

  async function fetchBaseApi(token) {
    const _token = token?.access_token ? token : newAuth.current
    const { access_token, refresh_token } = _token
    setLoading(true)
    try {
      const resProfile = await apiCore.getProfile(access_token)
      const enums = await apiCore.getEnums(access_token)
      // const resConfigs = await apiCore.getConfigs(access_token)
      // const resPermission = await apiCore.getPermissions(access_token)
      // const res = await Promise.all([resProfile, resConfigs, resPermission])
      // let configs = res[1]?.data
      // let baseData = {
      //   user: res[0]?.data,
      //   settings: res[1]?.data,
      //   permission: res[2]?.data?.permissions,
      //   access_token: access_token,
      //   settingTheme: getThemeConfigs(configs)
      // }
      let baseData = {
        user: resProfile.data,
        settings: configs,
        permission: permissions,
        enums: enums,
        access_token: access_token,
        settingTheme: getThemeConfigs(configs)
      }
      setIsModalConfirm(false)
      setCookieAuth({ access_token, refresh_token })
      setAuthStore(baseData)
      navigate("/dashboard")

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords
            localStorage.setItem(
              "location",
              `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
            )
            await apiCore.getProfile(access_token)
          },
          (error) => {
            console.error("Error getting location:", error)
          }
        )
      } else {
        const error = new Error("Geolocation is not supported by this browser.")
        console.error(error.message)
      }
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }
  const onChange = () => {
    setIsSubmited(true)
    setError("")
  }

  // Function to perform a demo login with predefined credentials
  function onLogin() {
    // Hardcoded demo credentials for testing
    const values = {
      username: "dattq_brand@gmail.com",
      password: "Demo@123"
    }

    // Call the onFinish function with the demo credentials
    onFinish(values)
  }

  function handleVerifyCode(token) {
    // setNewAuth(token)
    newAuth.current = token
    setIsModalOpen(false)
    setIsModalConfirm(true)
  }

  // Check if the environment is a demo environment
  // const isLocal = window.location.origin === "http://localhost:3000"

  useEffect(() => {
    if (user) {
      setUserData(user)
    }
  }, [user])

  useEffect(() => {
    if (rememberUsername) {
      setIsSubmited(true)
      form.setFieldsValue({
        username: rememberUsername,
        password: rememberPassword,
        remember: true
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rememberUsername])
  return (
    <div className="">
      <h3 className="text-[24px] text-primary-700 font-bold text-center mt-8 mb-6">
        Log in your account
      </h3>

      {error !== "" && <AlertError error={error} />}

      <Form name="normal_login" form={form} ref={formRef} onFinish={onFinish} layout="vertical">
        {/* Input field for the username */}
        <Form.Item
          label={<LabelInput label="Email" required={true} />}
          required={false}
          name="username"
          rules={[
            { required: true, message: "Email is required" },
            {
              validator: async (rule, value) => {
                if (value && isEmailValid(value)) {
                  return Promise.reject("Email is invalid!")
                }
                return Promise.resolve()
              }
            }
          ]}
        >
          <Input size="large" placeholder="example@gmail.com" onChange={onChange} />
        </Form.Item>

        {/* Input field for the password */}
        <Form.Item
          label={<LabelInput label="Password" required={true} />}
          name="password"
          required={false}
          rules={[
            { required: true, message: "Password is required" },
            {
              validator: async (rule, value) => {
                if (value && value.length < 6) {
                  return Promise.reject("Your password should have 6 characters or more")
                }
                if (value && hasWhiteSpace(value)) {
                  return Promise.reject("Your password should not have whitespace")
                }
                return Promise.resolve()
              }
            }
          ]}
          className="mb-8"
        >
          <Input.Password
            size="large"
            placeholder="Enter your password"
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            onChange={onChange}
          />
        </Form.Item>

        <div className="flex items-center justify-between mt-4">
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Remember login</Checkbox>
          </Form.Item>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <div className="text-primary-700 font-bold" onClick={onForgot}>
            Forgot password
          </div>
        </div>

        {/* Demo login button (visible only in demo environment) */}
        {true && (
          <div className="flex justify-center p-2 gap-2">
            <Button onClick={onLogin} loading={loading}>
              DemoLogin
            </Button>
          </div>
        )}

        {/* Submit button */}
        <Form.Item>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            className="w-full mt-5 !rounded-lg"
            loading={loading}
            disabled={!isSubmited}
          >
            Log in
          </Button>
        </Form.Item>
      </Form>

      <ModalVerifyCode
        user={userData}
        setUser={(v) => setUserData(v)}
        visible={isModalOpen}
        onSubmit={handleVerifyCode}
        onClose={() => setIsModalOpen(false)}
      />
      <ModalPopupConfirm
        visible={isModalConfirm}
        title={"Two-factor authentication is on"}
        desctiption={
          "Now when ever you sign in, we will ask you for a code after you enter email address and password"
        }
        loading={loading}
        onSubmit={fetchBaseApi}
      />
    </div>
  )
}

export default LoginForm

const permissions = [
  "delete_user",
  "add_support_ticket",
  "view_billing_statistic",
  "view_user_profile",
  "view_client",
  "delete_notification",
  "reset_client_password",
  "view_segment",
  "reset_user_password",
  "change_client_package",
  "update_billing_address",
  "change_segment",
  "remove_domain",
  "change_system_maintenance_setting",
  "view_system_maintenance_setting",
  "add_client",
  "delete_approval_setting",
  "view_application_setting",
  "change_group",
  "change_billing_payment_status",
  "add_approval_setting",
  "view_support_ticket",
  "view_billing_setting",
  "change_package",
  "view_dashboard",
  "reject_domain",
  "change_theme_setting",
  "add_package",
  "change_package_status",
  "change_billing_status",
  "change_user_profile",
  "download_invoice",
  "view_email_setting",
  "add_notification",
  "change_domain",
  "view_approval_setting",
  "add_group",
  "approve_approval",
  "view_approval",
  "view_package_version",
  "add_package_version",
  "approve_domain",
  "export_csv_client",
  "change_approval_setting",
  "delete_group",
  "change_activity_log",
  "export_csv_user",
  "change_client_status",
  "assign_user_role",
  "change_user",
  "view_theme_setting",
  "change_billing_setting",
  "view_activity_log",
  "change_user_status",
  "reject_approval",
  "view_security_setting",
  "change_my_notification",
  "assign_support_ticket",
  "view_notification",
  "change_billing",
  "view_general_setting",
  "view_my_notification",
  "change_general_setting",
  "change_notification",
  "add_approval",
  "view_package",
  "reopen_support_ticket",
  "delete_segment",
  "view_billing",
  "change_support_ticket",
  "view_user",
  "change_client",
  "view_group",
  "change_security_setting",
  "view_domain",
  "change_email_setting",
  "add_user",
  "close_support_ticket",
  "export_csv_group",
  "add_segment",
  "change_application_setting"
]

const configs = [
  {
    id: 91,
    key: "access-limit",
    value: {
      ip_addresses: {
        value: [
          {
            rule: 0,
            end_ip: "111.11.1.10",
            start_ip: "111.11.1.1",
            description: "Dải Ip đc phép login "
          }
        ],
        is_active: true
      },
      restrict_access: {
        value: true,
        is_active: true
      }
    },
    is_active: true,
    client_id: null,
    user_id: null,
    brand_id: null,
    created_at: "2024-06-24T09:29:43+0000",
    updated_at: "2024-07-05T05:10:34+0000",
    created_by: {
      id: 7,
      email: "dattq@gmail.com",
      avatar: null,
      username: null,
      client_id: null,
      last_name: "Tran",
      first_name: "Dat"
    },
    updated_by: {
      id: 18,
      email: "tuananh@gmail.com",
      avatar: null,
      username: null,
      client_id: null,
      last_name: "Anh",
      first_name: "Tuan"
    }
  },
  {
    id: 75,
    key: "notification-setting",
    value: {
      pause_time: 5,
      time_until: "2024-06-21T08:30:15.200Z",
      enable_category_setting: true,
      enable_email_notificatio: true,
      enable_email_notification: true,
      enable_pause_notification: true,
      enable_mobile_notification: true,
      pause_notification_setting: {
        key: 5,
        value: "2024-06-21T08:30:15.200Z"
      }
    },
    is_active: true,
    client_id: null,
    user_id: 19,
    brand_id: null,
    created_at: "2024-06-21T08:25:47+0000",
    updated_at: "2024-06-21T08:27:22+0000",
    created_by: {
      id: 19,
      email: "anhbq.hn24@gmail.com",
      avatar: null,
      username: null,
      client_id: null,
      last_name: "Quang",
      first_name: "Anh"
    },
    updated_by: {
      id: 19,
      email: "anhbq.hn24@gmail.com",
      avatar: null,
      username: null,
      client_id: null,
      last_name: "Quang",
      first_name: "Anh"
    }
  },
  {
    id: 72,
    key: "notification-setting",
    value: {
      pause_time: 2,
      enable_category_setting: true,
      enable_email_notificatio: true,
      enable_email_notification: true,
      enable_pause_notification: true,
      enable_mobile_notification: true,
      pause_notification_setting: {
        key: 2,
        value: "2024-06-21T06:57:44.087573Z"
      }
    },
    is_active: true,
    client_id: null,
    user_id: 22,
    brand_id: null,
    created_at: "2024-06-21T05:57:27+0000",
    updated_at: "2024-06-21T05:57:44+0000",
    created_by: {
      id: 22,
      email: "bienhoang@gmail.com",
      avatar: null,
      username: null,
      client_id: null,
      last_name: "Hoang",
      first_name: "Bien"
    },
    updated_by: {
      id: 22,
      email: "bienhoang@gmail.com",
      avatar: null,
      username: null,
      client_id: null,
      last_name: "Hoang",
      first_name: "Bien"
    }
  },
  {
    id: 58,
    key: "billing-setting",
    value: {
      city: "Hà Nội",
      logo: "https://edg-dev-edg-upload.s3.ap-northeast-1.amazonaws.com/images/aafa2357-bb54-44b7-8255-326dcc28e1c3",
      email: "supperadmin@gmail.com",
      state: "open",
      tax_id: "12234",
      country: "JP",
      address1: "Tòa nhà Minh Thu buliing, Ngõ 4 ,Đường Phú Mỹ, Mỹ Đình, Nam Từ Liêm, Hà Nội",
      address2: "84, Duy Tân, Cầu giấy, Hn",
      language: "en",
      postcode: "26062024",
      tax_name: "TAX-A",
      signature:
        "https://edg-dev-edg-upload.s3.ap-northeast-1.amazonaws.com/images/7be5a408-ee56-4475-9d1c-d1baecf596b8",
      billing_name: "Billing A",
      company_name: "NETKO SOLUTION",
      terms_and_conditions:
        "Dịch vụ của Google, được cung cấp miễn phí, dịch nhanh các từ, cụm từ và trang web giữa tiếng Anh và hơn 100 ngôn ngữ khác.",
      show_authorized_signatory: true
    },
    is_active: true,
    client_id: null,
    user_id: null,
    brand_id: null,
    created_at: "2024-06-20T07:00:32+0000",
    updated_at: "2024-06-26T08:31:48+0000",
    created_by: {
      id: 19,
      email: "anhbq.hn24@gmail.com",
      avatar: null,
      username: null,
      client_id: null,
      last_name: "Quang",
      first_name: "Anh"
    },
    updated_by: {
      id: 18,
      email: "tuananh@gmail.com",
      avatar: null,
      username: null,
      client_id: null,
      last_name: "Anh",
      first_name: "Tuan"
    }
  },
  {
    id: 57,
    key: "overview",
    value: {
      city: "Tokyo",
      email: "kosuke@edoglobe.jp",
      state: "Tokyo",
      country: "JP",
      address_1: "2915 N 21st Ave",
      address_2: "22",
      post_code: "85015",
      phone_code: "+84",
      company_name: "EdoGlobe",
      phone_number: "0123456789"
    },
    is_active: true,
    client_id: null,
    user_id: null,
    brand_id: null,
    created_at: "2024-06-20T06:58:41+0000",
    updated_at: "2024-06-25T06:44:04+0000",
    created_by: {
      id: 19,
      email: "anhbq.hn24@gmail.com",
      avatar: null,
      username: null,
      client_id: null,
      last_name: "Quang",
      first_name: "Anh"
    },
    updated_by: {
      id: 18,
      email: "tuananh@gmail.com",
      avatar: null,
      username: null,
      client_id: null,
      last_name: "Anh",
      first_name: "Tuan"
    }
  },
  {
    id: 28,
    key: "tfa-setting",
    value: {},
    is_active: false,
    client_id: null,
    user_id: null,
    brand_id: null,
    created_at: "2024-06-19T16:10:20+0000",
    updated_at: "2024-06-21T07:52:27+0000",
    created_by: {
      id: 2,
      email: "leomessi3051996@gmail.com",
      avatar:
        "https://edg-dev-edg-upload.s3.ap-northeast-1.amazonaws.com/images/68b8e268-6258-4619-9f1f-c9e7642145d5",
      username: null,
      last_name: "Hoàng",
      first_name: "huy"
    },
    updated_by: {
      id: 2,
      email: "leomessi3051996@gmail.com",
      avatar:
        "https://edg-dev-edg-upload.s3.ap-northeast-1.amazonaws.com/images/68b8e268-6258-4619-9f1f-c9e7642145d5",
      username: null,
      client_id: null,
      last_name: "Hoàng",
      first_name: "huy"
    }
  },
  {
    id: 27,
    key: "application-setting",
    value: {
      currency: "US",
      language: "jp",
      date_format: "YYYY-MM-DD",
      time_format: "HH:mm",
      number_format: "SK"
    },
    is_active: true,
    client_id: null,
    user_id: null,
    brand_id: null,
    created_at: "2024-06-18T23:40:10+0000",
    updated_at: "2024-07-09T04:47:49+0000",
    created_by: {
      email: "system@edo-globle.com"
    },
    updated_by: {
      id: 18,
      email: "tuananh@gmail.com",
      avatar: null,
      username: null,
      client_id: null,
      last_name: "Anh",
      first_name: "Tuan"
    }
  },
  {
    id: 19,
    key: "notification-setting",
    value: {
      enable_category_setting: true,
      enable_email_notificatio: true,
      enable_email_notification: true,
      enable_pause_notification: false,
      enable_mobile_notification: true
    },
    is_active: true,
    client_id: null,
    user_id: 3,
    brand_id: null,
    created_at: "2024-06-19T07:05:23+0000",
    updated_at: "2024-06-21T10:15:25+0000",
    created_by: {
      id: 3,
      email: "hoangbach14022023@gmail.com",
      avatar: null,
      username: null,
      last_name: "Bach",
      first_name: "Hoang"
    },
    updated_by: {
      id: 3,
      email: "hoangbach14022023@gmail.com",
      avatar:
        "https://edg-dev-edg-upload.s3.ap-northeast-1.amazonaws.com/images/1715512f-13b0-4e8c-a8fa-c4651b826354",
      username: null,
      client_id: null,
      last_name: "Bach",
      first_name: "Hoang"
    }
  },
  {
    id: 16,
    key: "password-policy",
    value: {
      numbers_required: {
        regex: "[0-9]",
        value: true,
        is_active: true
      },
      password_max_length: {
        regex: "^.{6,128}$",
        value: 128,
        is_active: true
      },
      password_min_length: {
        regex: "^.{6,}$",
        value: 6,
        is_active: true
      },
      allow_special_character: {
        regex: "[!@#$%^&*()_+]",
        value: "!@#$%^&*()_+",
        is_active: true
      },
      password_enforce_history: {
        regex: "",
        value: 1,
        is_active: true
      },
      lowercase_letter_required: {
        regex: "[a-z]",
        value: true,
        is_active: true
      },
      alphabetic_letter_required: {
        regex: "[a-zA-Z]",
        value: true,
        is_active: true
      }
    },
    is_active: true,
    client_id: null,
    user_id: null,
    brand_id: null,
    created_at: "2024-06-18T23:40:10+0000",
    updated_at: "2024-06-26T09:18:58+0000",
    created_by: {
      email: "system@edo-globle.com"
    },
    updated_by: {
      id: 18,
      email: "tuananh@gmail.com",
      avatar: null,
      username: null,
      client_id: null,
      last_name: "Anh",
      first_name: "Tuan"
    }
  },
  {
    id: 13,
    key: "payjp-setting",
    value: {
      environment: "live",
      live_secret: "1234",
      webhook_url: "http://edoglobe.com/billing-verify-webhook/63ecb1225e781291b87aed5beaf2768d",
      live_client_id: "1234"
    },
    is_active: true,
    client_id: null,
    user_id: null,
    brand_id: null,
    created_at: "2024-06-19T01:31:41+0000",
    updated_at: "2024-06-20T06:59:44+0000",
    created_by: {
      id: 3,
      email: "hoangbach14022023@gmail.com",
      avatar: null,
      username: null,
      last_name: "Bach",
      first_name: "Hoang"
    },
    updated_by: {
      id: 19,
      email: "anhbq.hn24@gmail.com",
      avatar: null,
      username: null,
      client_id: null,
      last_name: "Quang",
      first_name: "Anh"
    }
  },
  {
    id: 12,
    key: "stripe-setting",
    value: {
      environment: "live",
      live_secret: "sk_test_XXXXXxBnXjBe1d6G5rE",
      webhook_url: "http://edoglobe.com/billing-verify-webhook/63ecb1225e781291b87aed5beaf2768d",
      live_publishable_key: "sk_test_XXXXXxBnXjBe1d6G5rE",
      webhook_signing_secret: "sk_test_XXXXXxBnXjBe1d6G5rE"
    },
    is_active: true,
    client_id: null,
    user_id: null,
    brand_id: null,
    created_at: "2024-06-19T01:31:29+0000",
    updated_at: "2024-06-20T06:59:38+0000",
    created_by: {
      id: 3,
      email: "hoangbach14022023@gmail.com",
      avatar: null,
      username: null,
      last_name: "Bach",
      first_name: "Hoang"
    },
    updated_by: {
      id: 19,
      email: "anhbq.hn24@gmail.com",
      avatar: null,
      username: null,
      client_id: null,
      last_name: "Quang",
      first_name: "Anh"
    }
  },
  {
    id: 11,
    key: "sm-setting",
    value: {
      to: "2024-07-05T03:59:00.000Z",
      from: "2024-07-05T03:58:00.000Z",
      email_content: "<p>getrg</p>",
      is_send_email: true,
      subject_email: "rrhrehreh",
      system_content: "<p>rgregrge</p>",
      email_send_time: "2024-07-05T03:56:00.000Z",
      email_send_status: true,
      is_send_notification: true,
      notification_content: "<p>ewtrte</p>",
      subject_notification: "ỷeyr",
      notification_send_time: "2024-07-05T03:56:00.000Z",
      notification_send_status: true
    },
    is_active: false,
    client_id: null,
    user_id: null,
    brand_id: null,
    created_at: "2024-06-19T00:41:29+0000",
    updated_at: "2024-07-05T03:59:17+0000",
    created_by: {
      id: 3,
      email: "hoangbach14022023@gmail.com",
      avatar: null,
      username: null,
      last_name: "Bach",
      first_name: "Hoang"
    },
    updated_by: {
      id: 18,
      email: "tuananh@gmail.com",
      avatar: null,
      username: null,
      client_id: null,
      last_name: "Anh",
      first_name: "Tuan"
    }
  },
  {
    id: 9,
    key: "theme-setting",
    value: {
      logo: "https://edg-dev-edg-upload.s3.ap-northeast-1.amazonaws.com/images/8a1d4450-6d59-4bd0-b3e1-b24c5d5a28ec",
      name: "Edoglobe",
      favicon:
        "https://edg-dev-edg-upload.s3.ap-northeast-1.amazonaws.com/images/480635e4-9dd8-49af-9748-75c7bcf02a08",
      typography: "noto-sans-jp",
      apply_new_client: true,
      color_palette_type: 1
    },
    is_active: true,
    client_id: null,
    user_id: null,
    brand_id: null,
    created_at: "2024-06-18T23:52:44+0000",
    updated_at: "2024-07-09T03:59:11+0000",
    created_by: {
      id: 3,
      email: "hoangbach14022023@gmail.com",
      avatar: null,
      username: null,
      last_name: "Bach",
      first_name: "Hoang"
    },
    updated_by: {
      id: 18,
      email: "tuananh@gmail.com",
      avatar: null,
      username: null,
      client_id: null,
      last_name: "Anh",
      first_name: "Tuan"
    }
  },
  {
    id: 7,
    key: "notification-setting",
    value: {
      pause_time: 4,
      enable_category_setting: true,
      enable_email_notificatio: false,
      enable_email_notification: true,
      enable_pause_notification: true,
      enable_mobile_notification: false,
      pause_notification_setting: {
        key: 4,
        value: "2024-06-22T09:21:01.617709Z"
      }
    },
    is_active: true,
    client_id: null,
    user_id: 7,
    brand_id: null,
    created_at: "2024-06-18T16:38:51+0000",
    updated_at: "2024-06-21T09:21:01+0000",
    created_by: {
      id: 7,
      email: "dattq@gmail.com",
      avatar: null,
      username: null,
      last_name: "Tran",
      first_name: "Dat"
    },
    updated_by: {
      id: 7,
      email: "dattq@gmail.com",
      avatar: null,
      username: null,
      client_id: null,
      last_name: "Tran",
      first_name: "Dat"
    }
  },
  {
    id: 1,
    key: "notification-setting",
    value: {
      pause_time: 2,
      enable_category_setting: true,
      enable_email_notificatio: true,
      enable_email_notification: true,
      enable_pause_notification: true,
      enable_mobile_notification: true,
      pause_notification_setting: {
        key: 2,
        value: "2024-06-19T08:15:55.717502Z"
      }
    },
    is_active: true,
    client_id: null,
    user_id: 10,
    brand_id: null,
    created_at: "2024-06-18T09:15:21+0000",
    updated_at: "2024-06-19T07:15:55+0000",
    created_by: {
      id: 2,
      email: "tranquangdat1397@gmail.com",
      avatar:
        "https://edg-dev-edg-upload.s3.ap-northeast-1.amazonaws.com/images/68b8e268-6258-4619-9f1f-c9e7642145d5",
      username: null,
      last_name: "Dat",
      first_name: "TranTranTranTranTranTranTranTranTran"
    },
    updated_by: {
      id: 10,
      email: "quanganh@gmail.com",
      avatar: null,
      username: null,
      last_name: "Anh",
      first_name: "Quang"
    }
  }
]
