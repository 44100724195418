import Client from "../client/Client"
import version from "../version"
const resource = version + "/tenant/teacher"

function gets(params) {
  return Client.get(`${resource}/`, { params })
}
function get(id) {
  return Client.get(`${resource}/${id}/`)
}
function getClassInfomation(id) {
  return Client.get(`${resource}/${id}/class-information/`)
}
function updateClassInfomation(id, data) {
  return Client.put(`${resource}/${id}/class-information/`, data)
}
function create(data) {
  return Client.post(`${resource}/`, data)
}
function update(id, data) {
  return Client.put(`${resource}/${id}/`, data)
}
function remove(id) {
  return Client.delete(`${resource}/${id}/`)
}
function exportList(params) {
  return Client.post(`${resource}/export-csv/`, { params })
}

function updateBankAccount(id, data) {
  return Client.put(`${resource}/${id}/bank-account/`, data)
}

function getBankAccount(id) {
  return Client.put(`${resource}/${id}/bank-account/`)
}

function getsCompatibilityStudent(teacher_id, params) {
  return Client.get(`${resource}/${teacher_id}/compatibility-students/`, { params })
}

function updateGenderPreference(id, data) {
  return Client.put(`${resource}/${id}/gender-preference/`, data)
}

function getSalaryIncrease(id) {
  return Client.get(`${resource}/${id}/salary-increase/`)
}
function updateSalaryIncrease(id, data) {
  return Client.put(`${resource}/${id}/salary-increase/`, data)
}

function getPromotionHistory(params) {
  return Client.get(`${resource}/promotion-history/`, { params })
}

function getTeacherShifts(teacher_id, params) {
  return Client.get(`${resource}/${teacher_id}/shifts/`, { params })
}

const api = {
  gets,
  get,
  create,
  update,
  remove,
  exportList,
  updateBankAccount,
  getBankAccount,
  getsCompatibilityStudent,
  updateGenderPreference,
  getSalaryIncrease,
  getPromotionHistory,
  updateSalaryIncrease,
  getClassInfomation,
  updateClassInfomation,
  getTeacherShifts
}
export default api
