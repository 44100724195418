import Client from "../client/Client"
import version from "../version"
const resource = version + "/tenant/student"

function gets(student_id, params) {
  return Client.get(`${resource}/${student_id}/compatibility-teachers/`, { params })
}
function getListTeacherHistory(student_id, params) {
  return Client.get(`${resource}/${student_id}/teacher-class-histories/`, { params })
}
function getListTeacherInstruction(params) {
  return Client.get(`${version}/tenant/teacher/`, { params })
}
function getGender(student_id) {
  return Client.get(`${resource}/${student_id}/gender-preference/`)
}
function create(student_id, data) {
  return Client.post(`${resource}/${student_id}/compatibility-teachers/`, data)
}
function update(student_id, id, data) {
  return Client.put(`${resource}/${student_id}/compatibility-teachers/${id}/`, data)
}
function updateGender(student_id, data) {
  return Client.put(`${resource}/${student_id}/gender-preference/`, data)
}
function remove(student_id, id) {
  return Client.delete(`${resource}/${student_id}/compatibility-teachers/${id}/`)
}
function exportList(params) {
  return Client.post(`${resource}/export-csv/`, { params })
}

const api = {
  gets,
  getListTeacherHistory,
  getListTeacherInstruction,
  getGender,
  create,
  update,
  remove,
  exportList,
  updateGender
}
export default api
