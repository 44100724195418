import Client from "../client/ClientNotification"
import version from "../version"
const resource = version + "/segment"

function gets(params) {
  return Client.get(`${resource}/view/`, { params })
}
function get(id) {
  return Client.get(`${resource}/detail/${id}/`)
}
function create(data) {
  return Client.post(`${resource}/create/`, data)
}
function update(id, data) {
  return Client.put(`${resource}/update/${id}/`, data)
}
function remove(id) {
  return Client.delete(`${resource}/delete/${id}/`)
}

const api = {
  gets,
  get,
  create,
  update,
  remove
}
export default api
